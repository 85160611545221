<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s warp">
                <el-form-item>
                    <el-input type="text" placeholder="请输入商品成分名" v-model="query.componentName" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveGoodsComponentRelationInfo && permission.selectComponentManagementList"
                        @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'id'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="id" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="componentName" label="成分名" align="center" min-width="120">
                </el-table-column>
                <el-table-column prop="componentContent" label="成分含量(mg)" align="center" width="120">
                </el-table-column>
                <el-table-column label="操作" width="80px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateGoodsComponentRelationInfo && permission.selectComponentManagementList">
                            <el-button class=" success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeGoodsComponentRelationInfo">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
        </div>
        <el-dialog :title="addOrEditTitle" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true"
            fullscreen @close="resetAddOrEditData">
            <el-form label-width="120px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
                <el-form-item label="商品成分" prop="componentName">
                    <el-select v-model="addOrEditModel.componentName" placeholder="请选择商品成分" filterable :disabled="addOrEditModel.goodsComponentRelationId>0">
                        <el-option v-for="item in componentManagementList" :key="item.componentManagementId" :value="item.componentName
                            " :label="item.componentName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="成分含量(mg)" prop="componentContent">
                    <el-input type="number" size="medium" v-model="addOrEditModel.componentContent"
                        placeholder="请输入成分含量"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import treatmentManager from "@/request/treatmentManager.js";
export default {
    // import引入的组件需要注入到对象中才能使用
    props: ['goodsId', 'projectId'],
    mixins: [getList],
    components: {

    },
    data() {
        // 这里存放数据
        return {
            requst: {
                module: treatmentManager,
                url: 'goodsComponentRelationList',
                addUrl: 'saveGoodsComponentRelationInfo',
                updateUrl: 'updateGoodsComponentRelationInfo',
            },
            query: {
                goodsId: this.goodsId,
                projectId: this.projectId,
                componentName: ''
            },


            addOrEditDialog: false,
            addOrEditTitle: '',
            addOrEditBtnLoading: false,
            addOrEditModel: {
                goodsId: this.goodsId,
                projectId: this.projectId,
                goodsComponentRelationId: '',
                componentName: '',
                componentContent: '',
            },
            addRules: {
                componentName: [
                    { required: true, message: '请选择成分', trigger: 'blur' },
                ],
                componentContent: [
                    { required: true, message: '请输入成分含量', trigger: 'blur' },
                ],
            },

            componentManagementList: [],

        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.handlePermission()
        if (this.permission.selectComponentManagementList) {
            this.getComponentManagementList()
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async getComponentManagementList() {
            try {
                let data = await treatmentManager.componentManagementList()
                if (data.code == 200) {
                    this.componentManagementList = data.data
                }
            } catch (error) {
                //
            }
        },




        onAdd() {
            this.addOrEditTitle = '添加关联商品成分'
            this.addOrEditDialog = true
        },
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
                        let data = await this.requst.module[this.addOrEditModel.goodsComponentRelationId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.refresCurrentPagehData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                } else {
                    return false
                }
            })
        },
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.addOrEditModel = {
                goodsId: this.goodsId,
                projectId: this.projectId,
                goodsComponentRelationId: '',
                componentName: '',
                componentContent: ''
            }
        },
        async clickEditInfo(row) {
            this.addOrEditModel = {
                goodsId: row.goodsId,
                projectId: this.projectId,
                goodsComponentRelationId: row.goodsComponentRelationId,
                componentName: row.componentName,
                componentContent: row.componentContent,
            }
            this.addOrEditTitle = '修改关联商品成分'
            this.addOrEditDialog = true
        },
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await treatmentManager.removeGoodsComponentRelationInfo({
                    goodsComponentRelationId: row.goodsComponentRelationId,
                })
                if (code === 200) {
                    this.$message({ message: message, type: 'success', })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },
        onchoiceGoods(row) {
            this.handlePermission();
            if (this.permission.selectCompositionList) {
                this.dialogSelectCompositionListVisible = true
            } else {
                this.$message.error('您没有选择权限')
            }
        },
        changeSelectCompositionHandle(row) {
            this.addOrEditModel.goodsComponentRelationId = row.goodsComponentRelationId
            this.addOrEditModel.componentName = row.componentName
            this.dialogSelectCompositionListVisible = false
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
