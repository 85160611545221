import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
    constructor() {
        super()
    }
    //积分商品分类列表
    integralGoodsClassInfoList(param) {
        return this.get('web/integralGoodsClassInfo/list',param)
    }
    //积分商品分类新增
    saveIntegralGoodsClassInfo(param) {
        return this.post('web/integralGoodsClassInfo/save',param)
    }
    //积分商品分类修改
    updatenItegralGoodsClassInfo(param) {
        return this.post('web/integralGoodsClassInfo/update',param)
    }
    //积分商品分类删除
    removeIntegralGoodsClassInfo(param) {
        return this.post('web/integralGoodsClassInfo/remove',param)
    }


    //积分商品列表
    integralGoodsInfoList(param) {
        return this.get('web/integralGoodsInfo/list',param)
    }
    //积分商品分类新增
    saveIntegralGoodsInfo(param) {
        return this.post('web/integralGoodsInfo/save',param)
    }
    //积分商品分类修改
    updateItegralGoodsInfo(param) {
        return this.post('web/integralGoodsInfo/update',param)
    }
    //积分商品分类删除
    removeIntegralGoodsInfo(param) {
        return this.post('web/integralGoodsInfo/remove',param)
    }

    //积分商品列表
    integralGoodsGalleryInfoList(param) {
        return this.get('web/integralGoodsGalleryInfo/list',param)
    }
    //积分商品分类新增
    saveIntegralGoodsGalleryInfo(param) {
        return this.post('web/integralGoodsGalleryInfo/save',param)
    }
    //积分商品分类修改
    updateItegralGoodsGalleryInfo(param) {
        return this.post('web/integralGoodsGalleryInfo/update',param)
    }
    //积分商品分类删除
    removeIntegralGoodsGalleryInfo(param) {
        return this.post('web/integralGoodsGalleryInfo/remove',param)
    }


     //商品订单列表
    integralOrderInfoList(param) {
        return this.get('web/integralOrderInfo/list',param)
    }
    //商品订单地址
    getAddressInfo(param) {
        return this.get('web/orderAddressInfo/getAddressInfo',param)
    }
    //发货
    deliverGoods(param) {
        return this.post('web/integralOrderInfo/deliverGoods',param)
    }
    //确认自提
    confirmReceipt(param) {
        return this.post('web/integralOrderInfo/confirmReceipt',param)
    }
    //获取物流信息
    getLogisticsInfo(param) {
        return this.get('web/integralOrderInfo/getLogisticsInfo',param)
    }
    //获取物流信息
    updateIntegralOrderInfo(param) {
        return this.post('web/integralOrderInfo/update',param)
    }

}

export default new BaseManager()