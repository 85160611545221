<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="商品名称" v-model="query.goodsName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="商品分类名称" v-model="query.goodsClassName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.goodsType" placeholder="商品类型" v-if="sendGoodsType == '' || sendGoodsType == null"
            clearable>
            <el-option v-for="item in goodsTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.isShow" placeholder="是否上架" clearable>
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.isValueGoods" placeholder="是否是超值商品" clearable>
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain v-if="permission.saveIntegralGoodsInfo" @click="onAdd">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'goodsId'" v-loading="showLoading"
        :height="tableHeight">
        <el-table-column prop="goodsId" label="编号" align="center" width="50">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="主图" width="70" align="center">
          <template slot-scope="scope">
            <el-image style="max-width: 40px; max-height: 40px" class="table-td-thumb" :src="scope.row.goodsImg"
              :preview-src-list="[scope.row.goodsImg]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称" min-width="140">
        </el-table-column>
        <el-table-column prop="goodsClassName" label="商品分类" width="90" align="center">
        </el-table-column>
        <el-table-column label="商品类型" width="95" align="center">
          <template slot-scope="scope">
            {{ scope.row.goodsType | getStateName(goodsTypeList) }}
          </template>
        </el-table-column>
        <el-table-column prop="goodsStock" label="商品库存" width="70" align="center">
        </el-table-column>
        <el-table-column prop="goodsIntegral" label="商品积分" width="70" align="center">
        </el-table-column>
        <el-table-column prop="goodsPrice" label="商品价格" width="70" align="center">
        </el-table-column>
        <el-table-column label="提货方式" width="85" align="center">
          <template slot-scope="scope">
            {{ scope.row.supportType | getStateName(supportTypeList) }}
          </template>
        </el-table-column>
        <el-table-column prop="freightFees" label="普通地区运费" width="95" align="center">
        </el-table-column>
        <el-table-column prop="remoteFees" label="偏远地区运费" width="95" align="center">
        </el-table-column>
        <el-table-column prop="goodsClassName" label="商品图集" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 1)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsClassName" label="商品介绍" width="75" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 2)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="商品成分" align="center" width="75">
          <template slot-scope="scope">
            <el-link @click="showContenHandle(scope.row, 3)" type="primary"
              v-if="permission.lookGoodsComponentRelationList">查看</el-link>
          </template>
        </el-table-column>

        <el-table-column prop="isValueGoods" label="超值商品" align="center" width="70px">
          <template slot-scope="scope">
            <i :class="[scope.row.isValueGoods == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="isShow" label="是否上架" align="center" width="75px">
          <template slot-scope="scope">
            <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="orderWeight" label="权重" align="center" width="70">
        </el-table-column>
        <el-table-column label="操作" width="90px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top"
              v-if="permission.updateIntegralGoodsInfo">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(scope.row)">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top"
              v-if="permission.removeIntegralGoodsInfo">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="选择" placement="top" v-if="permission.selectGoods">
              <el-button icon="el-icon-circle-check" type="text" class="success" @click="selectGoodsHandle(scope.row)">
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="query.page" :page-sizes="[10, 30, 50, 100]" :page-size="query.page_size"
        layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 添加商品分类-->
    <el-dialog :title="`${editForm.goodsId > 0 ? '编辑' : '添加'}商品`" :visible.sync="addOrEditDialog" :modal="false"
      :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="150px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input size="medium" v-model="editForm.goodsName" placeholder="请填写商品名称"></el-input>
        </el-form-item>
        <el-form-item label="商品主图" prop="goodsImg">
          <UploadImageNewOne :limit="1" :imgList.sync="editForm.goodsImg" accept="image/*"></UploadImageNewOne>
          <span style="color:red">最优比例1:1</span>
        </el-form-item>
        <el-form-item label="商品类型" prop="goodsType" v-if="editForm.goodsId == 0">
          <el-select v-model="editForm.goodsType" placeholder="请选择商品类型" @change="changeGoodsTypeHandle">
            <el-option v-for="item in goodsTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类别" prop="goodsClassId">
          <el-select v-model="editForm.goodsClassId" placeholder="请选择商品类别" clearable>
            <el-option v-for="item in goodsClassList" :key="item.goodsClassId" :label="item.goodsClassName"
              :value="item.goodsClassId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品价格" prop="goodsPrice">
          <el-input type="text" v-model="editForm.goodsPrice" placeholder="请填写商品价格"></el-input>
        </el-form-item>
        <el-form-item label="积分" prop="goodsIntegral" v-if="editForm.goodsType != 3 && editForm.goodsType != 4">
          <el-input type="text" v-model="editForm.goodsIntegral" placeholder="请填写积分"></el-input>
        </el-form-item>
        <el-form-item label="提货方式" prop="supportType">
          <el-select v-model="editForm.supportType" placeholder="请选择提货方式" @change="changeSupportTypeHandle"
            :disabled="editForm.goodsType == 3 || editForm.goodsType == 4">
            <el-option v-for="item in supportTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="普通地区运费" prop="freightFees" v-if="editForm.supportType != 1">
          <el-input type="number" v-model="editForm.freightFees" placeholder="请填写普通地区运费"></el-input>
        </el-form-item>
        <el-form-item label="偏远地区运费" prop="remoteFees" v-if="editForm.supportType != 1">
          <el-input type="number" v-model="editForm.remoteFees" placeholder="请填写偏远地区运费"></el-input>
        </el-form-item>
        <el-form-item label="虚拟销量" prop="virtualSalesNum">
          <el-input type="number" v-model="editForm.virtualSalesNum" placeholder="请填写虚拟销量"></el-input>
        </el-form-item>
        <el-form-item label="商品库存" prop="goodsStock">
          <el-input type="number" v-model="editForm.goodsStock" placeholder="请填写商品库存"></el-input>
        </el-form-item>
        <el-form-item label="商品介绍" prop="goodsIntroduce">
          <Editor v-if="addOrEditDialog" v-model="editForm.goodsIntroduce"></Editor>
        </el-form-item>
        <el-form-item label="是否上架" prop="isShow">
          <el-checkbox v-model="editForm.isShow" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <el-form-item label="是否是超值商品" prop="isValueGoods">
          <el-checkbox v-model="editForm.isValueGoods" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <el-form-item label="权重" prop="orderWeight">
          <el-input type="number" v-model="editForm.orderWeight" placeholder="请填写权重"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 查看车主服务类别详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
    <el-dialog title="商品图集" :visible.sync="dialogLookGalleryVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <GoodsGalleryList :goodsId="lookGalleryModel.goodsId" v-if="dialogLookGalleryVisible" />
    </el-dialog>
    <!-- 商品成分 -->
    <el-dialog title="商品成分" :visible.sync="dialogGoodsCompositionListVisible" :modal="false" :destroy-on-close="true"
      fullscreen class="absolute">
      <GoodsCompositionList :goodsId="currnetGoodsID" v-if="dialogGoodsCompositionListVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import goodsManager from '@/request/goodsManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import Editor from '@/components/common/subgroup/Editor.vue' //编辑器
import UploadImageNewOne from '@/components/common/subgroup/UploadImageNewOne.vue' //上传图片
import GoodsGalleryList from './GoodsGalleryList.vue'
import GoodsCompositionList from '@/components/page/goods/GoodsCompositionList.vue'

export default {
  mixins: [getList],
  components: {
    ShowContent,
    Editor,
    UploadImageNewOne,
    GoodsGalleryList,
    GoodsCompositionList
  },
  props: ['goodsType'],
  data() {
    return {
      requst: {
        module: goodsManager,
        url: 'integralGoodsInfoList',
        addUrl: 'saveIntegralGoodsInfo',
        updateUrl: 'updateItegralGoodsInfo',
      },
      sendGoodsType: '',
      query: {
        goodsName: '',
        goodsType: this.goodsType,
        goodsClassName: '',
        isShow: '',
        isValueGoods: '',
      },
      dialogSeeContent: false,
      rowInfo: {},
      //添加
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        goodsId: 0,
        goodsName: '',
        goodsIntroduce: '',
        goodsIntegral: '',
        goodsType: '',
        goodsStock: '',
        freightFees: '',
        remoteFees: '',
        goodsClassId: '',
        virtualSalesNum: '',
        goodsImg: '',
        supportType: 0,
        goodsPrice: '',
        isValueGoods: 0,
        isShow: 1,
        orderWeight: 9,
      },
      addRules: {
        goodsName: [
          { required: true, message: '请填写商品名称', trigger: 'blur' },
        ],
        goodsIntroduce: [
          { required: true, message: '请填写商品介绍', trigger: 'blur' },
        ],
        goodsIntegral: [
          { required: true, message: '请填写商品积分', trigger: 'change' },
          {
            pattern: /^[+]{0,1}(\d+)$/,
            message: '商品积分必须为大于等于的0整数',
            trigger: 'change',
          },
        ],
        goodsPrice: [
          { required: true, message: '请填写商品价格', trigger: 'change' },
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: '商品价格必须为大于等于的0(并且只能保留两位小数)',
            trigger: 'change',
          },
        ],
        virtualSalesNum: [
          { required: true, message: '请填写虚拟销量', trigger: 'change' },
          {
            pattern: /^[+]{0,1}(\d+)$/,
            message: '虚拟销量必须为大于等于的0整数',
            trigger: 'change',
          },
        ],
        goodsType: [
          { required: true, message: '请选择商品类型', trigger: 'blur' },
        ],
        goodsStock: [
          { required: true, message: '请填写商品库存', trigger: 'change' },
          {
            pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
            message: '商品库存必须大于0',
            trigger: 'change',
          },
        ],
        freightFees: [
          { required: true, message: '请填写普通地区运费', trigger: 'change' },
        ],
        remoteFees: [
          { required: true, message: '请填写偏远地区运费', trigger: 'change' },
        ],
        goodsClassId: [
          { required: true, message: '请选择商品分类', trigger: 'blur' },
        ],
        goodsImg: [{ required: true, message: '请上传主图', trigger: 'blur' }],
        orderWeight: [
          { required: true, message: '请填写权重', trigger: 'blur' },
        ],
      },
      goodsTypeList: [
        {
          value: 1,
          label: '积分商品',
        },
        {
          value: 2,
          label: '邀请好友奖品',
        },
        {
          value: 3,
          label: '院线项目',
        },
        {
          value: 4,
          label: '家居项目',
        },
      ],
      isShowList: [
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        },
      ],
      supportTypeList: [
        {
          value: 0,
          label: '都支持',
        },
        {
          value: 1,
          label: '仅支持自提',
        },
        {
          value: 2,
          label: '仅支持快递',
        },
      ],
      goodsClassList: [],
      //查看商品图集
      dialogLookGalleryVisible: false,
      lookGalleryModel: {
        goodsId: 0,
      },

      //产品成分
      currnetGoodsID: 0,
      dialogGoodsCompositionListVisible: false,
    }
  },
  created() {
    this.sendGoodsType = this.goodsType
    this.handlePermission()
    if (this.permission.selectIntegralGoodsClassInfoList) {
      this.getIntegralGoodsClassInfoList()
    }
  },
  methods: {
    async getIntegralGoodsClassInfoList() {
      try {
        let data = await goodsManager.integralGoodsClassInfoList()
        if (data.code == 200) {
          this.goodsClassList = data.data
        }
      } catch (error) {
        //
      }
    },
    // 点击添加
    onAdd() {
      this.addOrEditDialog = true
    },
    showContenHandle(row, type) {
      switch (type) {
        case 1:
          if (this.permission.lookIntegralGoodsGalleryList) {
            this.lookGalleryModel.goodsId = row.goodsId
            this.dialogLookGalleryVisible = true
          } else {
            this.$message.error('您没有查看商品图集的权限')
          }
          break
        case 2:
          this.rowInfo.title = '商品介绍'
          this.rowInfo.content = row.goodsIntroduce
          this.dialogSeeContent = true
          break
        case 3:
          this.currnetGoodsID = row.goodsId
          this.dialogGoodsCompositionListVisible = true
          break;
        default:
          break
      }
    },

    // 点击添加---取消
    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.editForm))
            let data = await this.requst.module[
              this.editForm.goodsId > 0
                ? this.requst.updateUrl
                : this.requst.addUrl
            ](dataValue)
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: 'success',
              })
              this.refresCurrentPagehData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }
        }
      })
    },

    // 清除添加数据
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.editForm = {
        goodsId: 0,
        goodsName: '',
        goodsIntroduce: '',
        goodsIntegral: '',
        goodsType: '',
        goodsStock: '',
        freightFees: '',
        remoteFees: '',
        goodsClassId: '',
        virtualSalesNum: '',
        goodsImg: '',
        supportType: 0,
        goodsPrice: '',
        isValueGoods: 0,
        isShow: 1,
        orderWeight: 9,
      }
    },
    // 点击编辑信息
    async clickEditInfo(row) {
      this.editForm = {
        goodsId: row.goodsId,
        goodsName: row.goodsName,
        goodsIntroduce: row.goodsIntroduce,
        goodsIntegral: row.goodsIntegral,
        goodsType: row.goodsType,
        goodsStock: row.goodsStock,
        freightFees: row.freightFees,
        remoteFees: row.remoteFees,
        goodsClassId: row.goodsClassId,
        virtualSalesNum: row.virtualSalesNum,
        goodsImg: row.goodsImg,
        supportType: row.supportType,
        goodsPrice: row.goodsPrice ? row.goodsPrice : 0,
        isValueGoods: row.isValueGoods,
        isShow: row.isShow,
        orderWeight: row.orderWeight,
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin(row, index) {
      try {
        let { code, message } = await goodsManager.removeIntegralGoodsInfo({
          goodsId: row.goodsId,
        })
        if (code === 200) {
          this.$message({
            message: message,
            type: 'success',
          })
          this.delRefresh(index)
        }
      } catch (error) {
        console.log(error)
      }
    },
    changeGoodsTypeHandle(val) {
      if (val == 3 || val == 4) {
        this.editForm.supportType = 1
        this.editForm.goodsIntegral = 0
        this.editForm.freightFees = 0;
        this.editForm.remoteFees = 0;
      }
    },
    changeSupportTypeHandle() {
      this.editForm.freightFees = 0;
      this.editForm.remoteFees = 0;
    },
    //选择
    selectGoodsHandle(row) {
      this.$emit('changeSelectGoods', row)
    },
  },
}
</script>

<style lang="scss" scoped></style>
