var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "商品名称" },
                    model: {
                      value: _vm.query.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "goodsName", $$v)
                      },
                      expression: "query.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "商品分类名称" },
                    model: {
                      value: _vm.query.goodsClassName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "goodsClassName", $$v)
                      },
                      expression: "query.goodsClassName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.sendGoodsType == "" || _vm.sendGoodsType == null
                    ? _c(
                        "el-select",
                        {
                          attrs: { placeholder: "商品类型", clearable: "" },
                          model: {
                            value: _vm.query.goodsType,
                            callback: function ($$v) {
                              _vm.$set(_vm.query, "goodsType", $$v)
                            },
                            expression: "query.goodsType",
                          },
                        },
                        _vm._l(_vm.goodsTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "是否上架", clearable: "" },
                      model: {
                        value: _vm.query.isShow,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isShow", $$v)
                        },
                        expression: "query.isShow",
                      },
                    },
                    _vm._l(_vm.isShowList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "是否是超值商品", clearable: "" },
                      model: {
                        value: _vm.query.isValueGoods,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isValueGoods", $$v)
                        },
                        expression: "query.isValueGoods",
                      },
                    },
                    _vm._l(_vm.isShowList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.saveIntegralGoodsInfo
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "goodsId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "goodsId",
                  label: "编号",
                  align: "center",
                  width: "50",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "主图", width: "70", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            staticStyle: {
                              "max-width": "40px",
                              "max-height": "40px",
                            },
                            attrs: {
                              src: scope.row.goodsImg,
                              "preview-src-list": [scope.row.goodsImg],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsName",
                  label: "商品名称",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsClassName",
                  label: "商品分类",
                  width: "90",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "商品类型", width: "95", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.goodsType,
                                _vm.goodsTypeList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsStock",
                  label: "商品库存",
                  width: "70",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsIntegral",
                  label: "商品积分",
                  width: "70",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsPrice",
                  label: "商品价格",
                  width: "70",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "提货方式", width: "85", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.supportType,
                                _vm.supportTypeList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "freightFees",
                  label: "普通地区运费",
                  width: "95",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remoteFees",
                  label: "偏远地区运费",
                  width: "95",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsClassName",
                  label: "商品图集",
                  width: "75",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showContenHandle(scope.row, 1)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("详情"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsClassName",
                  label: "商品介绍",
                  width: "75",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showContenHandle(scope.row, 2)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("详情"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  label: "商品成分",
                  align: "center",
                  width: "75",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.lookGoodsComponentRelationList
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showContenHandle(scope.row, 3)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isValueGoods",
                  label: "超值商品",
                  align: "center",
                  width: "70px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isValueGoods == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isShow",
                  label: "是否上架",
                  align: "center",
                  width: "75px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isShow == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderWeight",
                  label: "权重",
                  align: "center",
                  width: "70",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "90px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.updateIntegralGoodsInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-edit-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.removeIntegralGoodsInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteAdmin(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.selectGoods
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "选择",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    icon: "el-icon-circle-check",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectGoodsHandle(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.page,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.page_size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.goodsId > 0 ? "编辑" : "添加"}商品`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "150px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "goodsName" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请填写商品名称" },
                    model: {
                      value: _vm.editForm.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "goodsName", $$v)
                      },
                      expression: "editForm.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品主图", prop: "goodsImg" } },
                [
                  _c("UploadImageNewOne", {
                    attrs: {
                      limit: 1,
                      imgList: _vm.editForm.goodsImg,
                      accept: "image/*",
                    },
                    on: {
                      "update:imgList": function ($event) {
                        return _vm.$set(_vm.editForm, "goodsImg", $event)
                      },
                      "update:img-list": function ($event) {
                        return _vm.$set(_vm.editForm, "goodsImg", $event)
                      },
                    },
                  }),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("最优比例1:1"),
                  ]),
                ],
                1
              ),
              _vm.editForm.goodsId == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品类型", prop: "goodsType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择商品类型" },
                          on: { change: _vm.changeGoodsTypeHandle },
                          model: {
                            value: _vm.editForm.goodsType,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "goodsType", $$v)
                            },
                            expression: "editForm.goodsType",
                          },
                        },
                        _vm._l(_vm.goodsTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "商品类别", prop: "goodsClassId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择商品类别", clearable: "" },
                      model: {
                        value: _vm.editForm.goodsClassId,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "goodsClassId", $$v)
                        },
                        expression: "editForm.goodsClassId",
                      },
                    },
                    _vm._l(_vm.goodsClassList, function (item) {
                      return _c("el-option", {
                        key: item.goodsClassId,
                        attrs: {
                          label: item.goodsClassName,
                          value: item.goodsClassId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品价格", prop: "goodsPrice" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请填写商品价格" },
                    model: {
                      value: _vm.editForm.goodsPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "goodsPrice", $$v)
                      },
                      expression: "editForm.goodsPrice",
                    },
                  }),
                ],
                1
              ),
              _vm.editForm.goodsType != 3 && _vm.editForm.goodsType != 4
                ? _c(
                    "el-form-item",
                    { attrs: { label: "积分", prop: "goodsIntegral" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text", placeholder: "请填写积分" },
                        model: {
                          value: _vm.editForm.goodsIntegral,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "goodsIntegral", $$v)
                          },
                          expression: "editForm.goodsIntegral",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "提货方式", prop: "supportType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择提货方式",
                        disabled:
                          _vm.editForm.goodsType == 3 ||
                          _vm.editForm.goodsType == 4,
                      },
                      on: { change: _vm.changeSupportTypeHandle },
                      model: {
                        value: _vm.editForm.supportType,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "supportType", $$v)
                        },
                        expression: "editForm.supportType",
                      },
                    },
                    _vm._l(_vm.supportTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.editForm.supportType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "普通地区运费", prop: "freightFees" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: "请填写普通地区运费",
                        },
                        model: {
                          value: _vm.editForm.freightFees,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "freightFees", $$v)
                          },
                          expression: "editForm.freightFees",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.supportType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "偏远地区运费", prop: "remoteFees" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: "请填写偏远地区运费",
                        },
                        model: {
                          value: _vm.editForm.remoteFees,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "remoteFees", $$v)
                          },
                          expression: "editForm.remoteFees",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟销量", prop: "virtualSalesNum" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请填写虚拟销量" },
                    model: {
                      value: _vm.editForm.virtualSalesNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "virtualSalesNum", $$v)
                      },
                      expression: "editForm.virtualSalesNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品库存", prop: "goodsStock" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请填写商品库存" },
                    model: {
                      value: _vm.editForm.goodsStock,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "goodsStock", $$v)
                      },
                      expression: "editForm.goodsStock",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品介绍", prop: "goodsIntroduce" } },
                [
                  _vm.addOrEditDialog
                    ? _c("Editor", {
                        model: {
                          value: _vm.editForm.goodsIntroduce,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "goodsIntroduce", $$v)
                          },
                          expression: "editForm.goodsIntroduce",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否上架", prop: "isShow" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: _vm.editForm.isShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "isShow", $$v)
                      },
                      expression: "editForm.isShow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否是超值商品", prop: "isValueGoods" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: _vm.editForm.isValueGoods,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "isValueGoods", $$v)
                      },
                      expression: "editForm.isValueGoods",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权重", prop: "orderWeight" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请填写权重" },
                    model: {
                      value: _vm.editForm.orderWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "orderWeight", $$v)
                      },
                      expression: "editForm.orderWeight",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品图集",
            visible: _vm.dialogLookGalleryVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookGalleryVisible = $event
            },
          },
        },
        [
          _vm.dialogLookGalleryVisible
            ? _c("GoodsGalleryList", {
                attrs: { goodsId: _vm.lookGalleryModel.goodsId },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "商品成分",
            visible: _vm.dialogGoodsCompositionListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogGoodsCompositionListVisible = $event
            },
          },
        },
        [
          _vm.dialogGoodsCompositionListVisible
            ? _c("GoodsCompositionList", {
                attrs: { goodsId: _vm.currnetGoodsID },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }