import BaseModule from './utils/BaseModule'
import requestNew from './utils/requestNew.js'
import { baseconfig } from "@/config.js";
class BaseManager extends BaseModule {
    constructor() {
        super()
    }

    //分析结果配置列表
    analysisResulList(param) {
        return this.get('/web/analysisResultInfo/list', param)
    }
    //添加分析结果配置
    async saveAnalysisResultInfo(param) {
        return await requestNew({
            url: "/web/analysisResultInfo/save",
            method: 'post',
            data: param
        })
    }
    //修改分析结果配置
    async updateAnalysisResultInfo(param) {
        return await requestNew({
            url: "/web/analysisResultInfo/update",
            method: 'post',
            data: param
        })
    }
    //删除分析结果配置
    removeAnalysisResultInfo(param) {
        return this.post('/web/analysisResultInfo/remove', param)
    }

    /*********** 治疗方案 **************/
    // 治疗方案
    treatmentProjectInfoList(param) {
        return this.get('/web/treatmentProject/list', param)
    }
    //添加治疗方案
    async saveTreatmentProjectInfo(param) {
        return await requestNew({
            url: "/web/treatmentProject/save",
            method: 'post',
            data: param
        })
    }
    //修改治疗方案
    async updateTreatmentProjectInfo(param) {
        return await requestNew({
            url: "/web/treatmentProject/update",
            method: 'post',
            data: param
        })
    }
    //删除治疗方案
    removeTreatmentProjectInfo(param) {
        return this.post('/web/treatmentProject/remove', param)
    }

    /*********** 治疗方案关联商品 **************/
    //治疗方案关联商品列表
    treatmentProjectGoodsRelationList(param) {
        return this.get('/web/treatmentProjectGoodsRelation/list', param)
    }
    //添加治疗方案关联商品
    async saveTreatmentProjectGoodsRelationInfo(param) {
        return await requestNew({
            url: "/web/treatmentProjectGoodsRelation/save",
            method: 'post',
            data: param
        })
    }
    //修改治疗方案关联商品
    async updateTreatmentProjectGoodsRelationInfo(param) {
        return await requestNew({
            url: "/web/treatmentProjectGoodsRelation/update",
            method: 'post',
            data: param
        })
    }
    //删除治疗方案关联商品
    removeTreatmentProjectGoodsRelationInfo(param) {
        return this.post('/web/treatmentProjectGoodsRelation/remove', param)
    }

    /*********** 商品成分 **************/
    // 列表
    componentManagementList(param) {
        return this.get('/web/componentManagement/list', param)
    }
    //添加
    async saveComponentManagementInfo(param) {
        return await requestNew({
            url: "/web/componentManagement/save",
            method: 'post',
            data: param
        })
    }
    //修改
    async updateComponentManagementInfo(param) {
        return await requestNew({
            url: "/web/componentManagement/update",
            method: 'post',
            data: param
        })
    }
    //删除
    removeComponentManagementInfo(param) {
        return this.post('/web/componentManagement/remove', param)
    }

    /*********** 商品关联成分 **************/
    // 列表
    goodsComponentRelationList(param) {
        return this.get('/web/goodsComponentRelation/list', param)
    }
    //添加
    async saveGoodsComponentRelationInfo(param) {
        return await requestNew({
            url: "/web/goodsComponentRelation/save",
            method: 'post',
            data: param
        })
    }
    //修改
    async updateGoodsComponentRelationInfo(param) {
        return await requestNew({
            url: "/web/goodsComponentRelation/update",
            method: 'post',
            data: param
        })
    }
    //删除
    removeGoodsComponentRelationInfo(param) {
        return this.post('/web/goodsComponentRelation/remove', param)
    }



}

export default new BaseManager()
